import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import './account.css';
import { useUploadProgress } from '../context/UploadProgressContext.js';

const API_URL = process.env.REACT_APP_API_URL;

const DESCRIPTION_THRESHOLD = 100;
const TITLE_THRESHOLD = 50;

const Account = () => {
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();
  const { progress, isUploading, processing } = useUploadProgress();

  const [userInfo, setUserInfo] = useState(null);
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(null);

  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupError, setGroupError] = useState(null);

  const [isManageMembersModalOpen, setIsManageMembersModalOpen] = useState(false);
  const [currentGroupMembers, setCurrentGroupMembers] = useState([]);
  const [memberError, setMemberError] = useState(null);
  const [loading, setLoading] = useState(true);

  // This state holds member group IDs for which the description is expanded.
  const [expandedMemberDescriptions, setExpandedMemberDescriptions] = useState([]);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchUserData = async () => {
        try {
          setLoading(true);
          const token = authHeader.split(' ')[1];
          const userResponse = await axios.get(`${API_URL}/user/info`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          setUserInfo(userResponse.data.userInfo);

          // Fetch user's groups
          const groupsResponse = await axios.get(`${API_URL}/group/my-groups`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          setGroups(groupsResponse.data.groups);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError(error.response?.data?.message || 'Error fetching data');
        } finally{
          setLoading(false);
        }
      };

      fetchUserData();
    }
  }, [isAuthenticated, authHeader]);

  useEffect(() => {
    if (isManageMembersModalOpen || isEditGroupModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  
    // Cleanup when the component unmounts
    return () => document.body.classList.remove('no-scroll');
  }, [isManageMembersModalOpen, isEditGroupModalOpen]);


  const handleAddGroup = async () => {
    if (!groupName.trim()) {
      setGroupError('Group name is required.');
      return;
    }
    try {
      const token = authHeader.split(' ')[1];
      const response = await axios.post(
        `${API_URL}/group`,
        {
          groupName,
          description: groupDescription,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setGroups([...groups, response.data.group]);
      setIsAddGroupModalOpen(false);
      setGroupName('');
      setGroupDescription('');
      setGroupError(null);
    } catch (error) {
      console.error('Error adding group:', error);
      setGroupError(error.response?.data?.message || 'Error adding group');
    }
  };

  const loadUserOptions = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    try {
      const token = authHeader.split(' ')[1];
      const response = await axios.get(`${API_URL}/group/search/users`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { query: inputValue },
      });
      const users = response.data.users;
      return users.map((user) => ({ value: user.memberToken, label: user.name }));
    } catch (error) {
      console.error('Error fetching user options:', error);
      return [];
    }
  };

  const handleEditGroup = async () => {
    if (!groupName.trim()) {
      setGroupError('Group name is required.');
      return;
    }
    try {
      const token = authHeader.split(' ')[1];
      const response = await axios.put(
        `${API_URL}/group/${currentGroup.groupId}`,
        {
          groupName,
          description: groupDescription,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setGroups(
        groups.map((group) =>
          group.groupId === currentGroup.groupId ? response.data.group : group
        )
      );
      setIsEditGroupModalOpen(false);
      setCurrentGroup(null);
      setGroupName('');
      setGroupDescription('');
      setGroupError(null);
    } catch (error) {
      console.error('Error editing group:', error);
      setGroupError(error.response?.data?.message || 'Error editing group');
    }
  };

  const handleDeleteGroup = async (groupId) => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      try {
        const token = authHeader.split(' ')[1];
        await axios.delete(`${API_URL}/group/${groupId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setGroups(groups.filter((group) => group.groupId !== groupId));
      } catch (error) {
        console.error('Error deleting group:', error);
        setError(error.response?.data?.message || 'Error deleting group');
      }
    }
  };

  const openEditGroupModal = (group) => {
    setCurrentGroup(group);
    setGroupName(group.groupName);
    setGroupDescription(group.description || '');
    setIsEditGroupModalOpen(true);
  };

  const fetchGroupMembers = async (groupId) => {
    try {
      const token = authHeader.split(' ')[1];
      const response = await axios.get(`${API_URL}/group/${groupId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setCurrentGroupMembers(response.data.group.members);
    } catch (error) {
      console.error('Error fetching group members:', error);
      setMemberError(error.response?.data?.message || 'Error fetching group members');
    }
  };

  const openManageMembersModal = (group) => {
    setCurrentGroup(group);
    setIsManageMembersModalOpen(true);
    fetchGroupMembers(group.groupId);
  };

  const handleAddMembers = async (selectedOptions) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return;
    }
    try {
      const token = authHeader.split(' ')[1];
      const memberTokensToAdd = selectedOptions.map((option) => option.value);

      await axios.post(
        `${API_URL}/group/${currentGroup.groupId}/members`,
        { memberTokens: memberTokensToAdd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      fetchGroupMembers(currentGroup.groupId);
    } catch (error) {
      console.error('Error adding members:', error);
      setMemberError(error.response?.data?.message || 'Error adding members');
    }
  };

  const handleRemoveMember = async (memberToken) => {
    if (window.confirm('Are you sure you want to remove this member?')) {
      try {
        const token = authHeader.split(' ')[1];
        await axios.delete(`${API_URL}/group/${currentGroup.groupId}/members`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { memberToken },
        });

        fetchGroupMembers(currentGroup.groupId);
      } catch (error) {
        console.error('Error removing member:', error);
        setMemberError(error.response?.data?.message || 'Error removing member');
      }
    }
  };

  // Toggle expand/collapse for a member group description
  const toggleDescription = (groupId) => {
    setExpandedMemberDescriptions((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId]
    );
  };

  const toggleTitle = (groupId) => {
    const titleKey = `title-${groupId}`;
    setExpandedMemberDescriptions((prev) =>
      prev.includes(titleKey)
        ? prev.filter((key) => key !== titleKey)
        : [...prev, titleKey]
    );
  };

  if (!isAuthenticated) {
    return <div>Please log in to view your account information.</div>;
  }

  

  

  // Owned Group Items — title and action buttons (Edit, Members, Delete)
  const ownedGroupItems = groups
  .filter((group) => group.isOwner)
  .map((group) => {
    // Title toggle setup
    const isTitleLong = group.groupName && group.groupName.length > TITLE_THRESHOLD;
    const isTitleExpanded = expandedMemberDescriptions.includes(`title-${group.groupId}`);
    const titleText = isTitleLong && !isTitleExpanded
      ? group.groupName.substring(0, TITLE_THRESHOLD) + '...'
      : group.groupName;

    // Description toggle setup
    const isDescriptionLong = group.description && group.description.length > DESCRIPTION_THRESHOLD;
    const isDescriptionExpanded = expandedMemberDescriptions.includes(group.groupId);
    const descriptionText = isDescriptionLong && !isDescriptionExpanded
      ? group.description.substring(0, DESCRIPTION_THRESHOLD) + '...'
      : group.description;

    return (
      <li key={group.groupId} className="acc-group-list-item">
        <div className="acc-group-item owned-group">
          <div className="acc-group-header">
            <span 
              className={`acc-group-name expanded ${isTitleExpanded ? 'expanded' : ''}`}
            >
              {titleText}
              {isTitleLong && (
                <span
                  onClick={() => toggleTitle(group.groupId)}
                  className="read-toggle"
                >
                  {isTitleExpanded ? ' Read Less' : ' Read More'}
                </span>
              )}
            </span>
          </div>
          {group.description && (
            <p 
              className={`acc-group-description expanded ${isDescriptionExpanded ? 'expanded' : ''}`}
            >
              {descriptionText}
              {isDescriptionLong && (
                <span
                  onClick={() => toggleDescription(group.groupId)}
                  className="read-toggle"
                >
                  {isDescriptionExpanded ? ' Read Less' : ' Read More'}
                </span>
              )}
            </p>
          )}
          <div className="acc-group-actions">
            <button className="edit-button" onClick={() => openEditGroupModal(group)}>
              Edit
            </button>
            <button className="manage-members-button" onClick={() => openManageMembersModal(group)}>
              Members
            </button>
            <button className="delete-button" onClick={() => handleDeleteGroup(group.groupId)}>
              Delete
            </button>
          </div>
        </div>
      </li>
    );
  });

    
  // Member (non‑owned) Group Items — title, full description (or toggleable truncated version), and a single "Members" button (if needed)
  const memberGroupItems = groups
    .filter((group) => !group.isOwner)
    .map((group) => {
      // Title toggle setup
      const isTitleLong = group.groupName && group.groupName.length > TITLE_THRESHOLD;
      const isTitleExpanded = expandedMemberDescriptions.includes(`title-${group.groupId}`);
      const titleText = isTitleLong && !isTitleExpanded
        ? group.groupName.substring(0, TITLE_THRESHOLD) + '...'
        : group.groupName;

      // Description toggle setup (as before)
      const isDescriptionLong = group.description && group.description.length > DESCRIPTION_THRESHOLD;
      const isDescriptionExpanded = expandedMemberDescriptions.includes(group.groupId);
      const descriptionText = isDescriptionLong && !isDescriptionExpanded
        ? group.description.substring(0, DESCRIPTION_THRESHOLD) + '...'
        : group.description;

      return (
        <li key={group.groupId} className="acc-group-list-item">
          <div className="acc-group-item member-group">
            <div className="acc-group-header">
              <span 
                className={`acc-group-name expanded ${isTitleExpanded ? 'expanded' : 'truncated'}`}
              >
                {titleText}
                {isTitleLong && (
                  <span
                    onClick={() => toggleTitle(group.groupId)}
                    className="read-toggle"
                  >
                    {isTitleExpanded ? ' Read Less' : ' Read More'}
                  </span>
                )}
              </span>
            </div>
            {group.description && (
              <p 
                className={`acc-group-description full-description expanded ${isDescriptionExpanded ? 'expanded' : 'truncated'}`}
              >
                {descriptionText}
                {isDescriptionLong && (
                  <span
                    onClick={() => toggleDescription(group.groupId)}
                    className="read-toggle"
                  >
                    {isDescriptionExpanded ? ' Read Less' : ' Read More'}
                  </span>
                )}
              </p>
            )}
            <div className="acc-group-actions">
              {/* You can include other controls here */}
            </div>
          </div>
        </li>
      );
    });

  return (
    <div className="account-page">
      {loading && (
        <div className="acc-loader-wrapper">
          <div className="acc-loader"></div>
        </div>
      )}
      {/* LEFT PANEL - narrower column for user info */}
      <div className="account-left">
        {userInfo && (
          <div className="user-info-card">
            <div className="avatar-placeholder">
              {userInfo.name?.charAt(0) || 'U'}
            </div>
            <h2 className="user-name">{userInfo.name}, M.D.</h2>
            <p className="user-meta">
              {userInfo.organisation} | {userInfo.specialisation}
            </p>
            <div className="account-links">
              {/* Future expansion */}
            </div>
          </div>
        )}
      </div>

      {/* RIGHT PANEL - group management */}
      <div className="account-right">
        {error && <div className="error">{error}</div>}

        <div className="groups-dual-layout">
          {/* Owned Groups Panel */}
          <div className="owned-groups-panel">
            <div className="panel-header">
              <h2>Owned</h2>
              <button
                className="add-group-button"
                onClick={() => {
                  setIsAddGroupModalOpen(true);
                  setGroupError(null);
                }}
              >
                + Add
              </button>
            </div>
            <div className="group-list-wrapper">
              {ownedGroupItems.length > 0 ? (
                <ul className="group-list">{ownedGroupItems}</ul>
              ) : (
                <p>No owned groups yet.</p>
              )}
            </div>
          </div>

          {/* Member Groups Panel */}
          <div className="member-groups-panel">
            <h2>Member</h2>
            <div className="group-list-wrapper">
              {memberGroupItems.length > 0 ? (
                <ul className="group-list">{memberGroupItems}</ul>
              ) : (
                <p>You are not a member of any groups.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------- Modals (unchanged logic) ---------------------- */}
      {isManageMembersModalOpen && currentGroup && (
        <div className="modal">
          <div className="modal-content">
            <h3>Manage Members for {currentGroup.groupName}</h3>

            <h4>Group Owner:</h4>
            <p className="group-owner">
              {currentGroup.ownerName} <span className="owner-tag">(Owner)</span>
            </p>

            <h4>Current Members:</h4>
            <ul className="member-list">
  {/* Owner at the top */}
  {currentGroupMembers
    .filter((member) => member.isCurrentUser)
    .map((member) => {
      const isNameLong = member.name && member.name.length > TITLE_THRESHOLD;
      const isNameExpanded = expandedMemberDescriptions.includes(`member-${member.memberToken}`);
      const memberName = isNameLong && !isNameExpanded
        ? member.name.substring(0, TITLE_THRESHOLD) + '...'
        : member.name;

      return (
        <li key={member.memberToken} className="member-list-item owner-item">
          <span
            className={`member-name owner-name ${isNameExpanded ? 'expanded' : 'truncated'}`}
          >
            {memberName}
            {isNameLong && (
              <span
                onClick={() => toggleDescription(`member-${member.memberToken}`)}
                className="read-toggle"
              >
                {isNameExpanded ? ' Read Less' : ' Read More'}
              </span>
            )}
            <span className="owner-tag"> (Owner)</span>
          </span>
          {/* Owner button */}
          <button className="owner-indicator-button" disabled>
            Owner
          </button>
        </li>
      );
    })}

  {/* Other members */}
  {currentGroupMembers
    .filter((member) => !member.isCurrentUser)
    .map((member) => {
      const isNameLong = member.name && member.name.length > TITLE_THRESHOLD;
      const isNameExpanded = expandedMemberDescriptions.includes(`member-${member.memberToken}`);
      const memberName = isNameLong && !isNameExpanded
        ? member.name.substring(0, TITLE_THRESHOLD) + '...'
        : member.name;

      return (
        <li key={member.memberToken} className="member-list-item">
          <span className={`member-name ${isNameExpanded ? 'expanded' : 'truncated'}`}>
            {memberName}
            {isNameLong && (
              <span
                onClick={() => toggleDescription(`member-${member.memberToken}`)}
                className="read-toggle"
              >
                {isNameExpanded ? ' Read Less' : ' Read More'}
              </span>
            )}
          </span>
          <button
            className="remove-member-button"
            onClick={() => handleRemoveMember(member.memberToken)}
          >
            Remove
          </button>
        </li>
      );
    })}
</ul>

            <div className="modal-actions">
              <button
                className="close-button"
                onClick={() => {
                  setIsManageMembersModalOpen(false);
                  setCurrentGroup(null);
                  setCurrentGroupMembers([]);
                  setMemberError(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}


    {isAddGroupModalOpen && (
      <div className="modal">
        <div className="modal-content">
          <h3>Create a New Group</h3>
          {groupError && <div className="error">{groupError}</div>}

          <label htmlFor="newGroupName">
            <h4>Group Name</h4>
            <p className="input-subtitle">Give your group a meaningful name.</p>
          </label>
          <input
            id="newGroupName"
            type="text"
            placeholder="Enter group name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            className="input-field"
          />

          <label htmlFor="newGroupDescription">
            <h4>Group Description</h4>
            <p className="input-subtitle">Provide a short description to help identify this group.</p>
          </label>
          <textarea
            id="newGroupDescription"
            placeholder="Enter group description"
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
            className="input-field textarea-field"
          />

          <div className="modal-actions">
            <button className="save-button" onClick={handleAddGroup}>
              Create Group
            </button>
            <button
              className="cancel-button"
              onClick={() => {
                setIsAddGroupModalOpen(false);
                setGroupError(null);
                setGroupName('');
                setGroupDescription('');
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )}
    
    {isEditGroupModalOpen && currentGroup && (
      <div className="modal">
        <div className="modal-content">
          <h3>Edit Group</h3>
          {groupError && <div className="error">{groupError}</div>}

          <label htmlFor="groupNameInput">
            <h4>Group Name</h4>
            <p className="input-subtitle">Update the group's name as it will appear to others.</p>
          </label>
          <input
            id="groupNameInput"
            type="text"
            placeholder="Group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />

          <label htmlFor="groupDescriptionInput">
            <h4>Group Description</h4>
            <p className="input-subtitle">Provide a brief description for this group.</p>
          </label>
          <textarea
            id="groupDescriptionInput"
            placeholder="Description"
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
          />

          <div className="modal-actions">
            <button className="save-button" onClick={handleEditGroup}>
              Save Changes
            </button>
            <button
              className="cancel-button"
              onClick={() => {
                setIsEditGroupModalOpen(false);
                setGroupError(null);
                setCurrentGroup(null);
                setGroupName('');
                setGroupDescription('');
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )}

      
    </div>
  );
};

export default Account;