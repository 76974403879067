import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { ReactComponent as UploadVideoIcon } from '../../assets/upload-video.svg';

import './cases_home.css';

const API_URL = process.env.REACT_APP_API_URL;
const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_LINK;


// Create a Tooltip component that renders in a portal
const Tooltip = ({ x, y, text }) => {
  const style = {
    position: 'absolute',
    top: y,
    left: x,
    // Add any additional styling as needed
    zIndex: 1000
  };

  return ReactDOM.createPortal(
    <div className="group-hover-tooltip" style={style}>
      {text}
    </div>,
    document.body
  );
};

function CasesHome() {
  const [videoFiles, setVideoFiles] = useState([]);
  const [originalVideoFiles, setOriginalVideoFiles] = useState([]);
  const [surgeonInfo, setSurgeonInfo] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const authHeader = useAuthHeader();
  const navigate = useNavigate();

  const [annotatedVideos, setAnnotatedVideos] = useState({});
  const [privateVideos, setPrivateVideos] = useState({});

  const [annotatedFilter, setAnnotatedFilter] = useState(false);
  const [videoTypeFilter, setVideoTypeFilter] = useState('all');
  const [accessFilter, setAccessFilter] = useState('all');
  const [genderFilter, setGenderFilter] = useState('');

  const [showVideoTypeFilter, setShowVideoTypeFilter] = useState(true);
  const [showAccessFilter, setShowAccessFilter] = useState(true);
  const [showGenderFilter, setShowGenderFilter] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const [showFilters, setShowFilters] = useState(true);
  const [searchInput, setSearchInput] = useState('');

  const [userGroups, setUserGroups] = useState([]);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [hoveredGroupName, setHoveredGroupName] = useState(null);
  const [hoveredGroupPos, setHoveredGroupPos] = useState({ x: 0, y: 0 });

  // ---------------- NEW: Pagination-related ----------------
  const [page, setPage] = useState(1);
  const videosPerPage = 6; // 2 rows x 3 videos/row

  function getPaginationRange(currentPage, totalPages) {
    if (totalPages <= 4) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
    if (currentPage <= 2) {
      return [1, 2, 3, '...', totalPages];
    }
    if (currentPage >= totalPages - 2) {
      return [1, '...', totalPages - 2, totalPages - 1, totalPages];
    }
    return [1, '...',  currentPage, '...', totalPages];
  }

  // ---------------- Fetch initial data (accessible videos) ----------------
  // We only run this if no group is active.
  const fetchAccessibleVideos = async (token) => {
    try {
      const accessibleResponse = await axios.get(`${API_URL}/video/accessible`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
  
      const accessibleVideos = accessibleResponse.data || []; // Default to empty array if undefined
  
      const videoDataPromises = accessibleVideos.map(async (video) => {
        try {
          const statusResponse = await axios.get(`${API_URL}/video/${video.videoId}/status`, {
            headers: { 'Authorization': `Bearer ${token}` },
          });
  
          return {
            videoId: video.videoId,
            videoTitle: video.videoTitle || 'Untitled',
            videoDescription: video.videoDescription || 'No description.',
            surgeryType: video.surgeryType || 'Unknown Operation',
            surgerySpeciality: video.surgerySpeciality || 'Unknown Procedure',
            uploadedBy: video.uploadedBy || null,
            uploadDate: video.uploadDate || 'Unknown Date',
            status: statusResponse.data.status || 'unknown',
            thumbnailUrl: `${CLOUDFRONT_URL}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
            isPublic: video.isPublic !== undefined ? video.isPublic : true, // Default to public
          };
        } catch (statusError) {
          console.error(`Error fetching status for video ${video.videoId}:`, statusError);
          return {
            videoId: video.videoId,
            videoTitle: video.videoTitle || 'Untitled',
            videoDescription: video.videoDescription || 'No description.',
            surgeryType: video.surgeryType || 'Unknown Operation',
            surgerySpeciality: video.surgerySpeciality || 'Unknown Procedure',
            uploadedBy: video.uploadedBy || null,
            uploadDate: video.uploadDate || 'Unknown Date',
            status: 'unknown', // Default status on error
            thumbnailUrl: `${CLOUDFRONT_URL}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
            isPublic: video.isPublic !== undefined ? video.isPublic : true, // Default to public
          };
        }
      });
  
      const videosWithStatus = await Promise.all(videoDataPromises);
      return videosWithStatus; // Always return an array
    } catch (error) {
      console.error('Error fetching accessible videos:', error);
      setError('Error fetching accessible videos');
      return []; // Return empty array on error
    }
  };

  // ---------------- Fetch group videos ----------------
  const fetchGroupVideos = async (token, groupId) => {
    try {
      const response = await axios.get(`${API_URL}/group/${groupId}/videos`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const groupVideos = response.data.videos || []; // Default to empty array if undefined
  
      const videoDataPromises = groupVideos.map(async (video) => {
        try {
          const statusResponse = await axios.get(`${API_URL}/video/${video.videoId}/status`, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          return {
            ...video,
            status: statusResponse.data.status || 'unknown', // Default status
            thumbnailUrl: `${CLOUDFRONT_URL}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
          };
        } catch (statusError) {
          console.error(`Error fetching status for video ${video.videoId}:`, statusError);
          return {
            ...video,
            status: 'unknown', // Default status on error
            thumbnailUrl: `${CLOUDFRONT_URL}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
          };
        }
      });
  
      const videosWithStatus = await Promise.all(videoDataPromises);
      return videosWithStatus; // Always return an array
    } catch (error) {
      console.error('Error fetching group videos:', error);
      setError('Error fetching group videos');
      return []; // Return empty array on error
    }
  };

  // ---------------- Combined Data Loading useEffect ----------------
  // This useEffect watches for changes in videoTypeFilter, annotatedFilter, accessFilter, genderFilter, searchTerm, and currentGroupId.
  // When currentGroupId is not null, it fetches group videos.
  // Otherwise, it uses our filter path.

  
  useEffect(() => {
    const fetchDataAndMetadata = async () => {
      setLoading(true);
      const startTime = Date.now(); // For minimum loading duration
      const token = authHeader.split(' ')[1];
  
      try {
        let fetchedVideos = [];
  
        // Fetch Video Data
        if (currentGroupId) {
          const groupVideosResponse = await fetchGroupVideos(token, currentGroupId);
          fetchedVideos = groupVideosResponse;
        } else {
          if (searchTerm.trim() !== '') {
            const searchResponse = await axios.get(`${API_URL}/video/search`, {
              headers: { 'Authorization': `Bearer ${token}` },
              params: { searchTerm }
            });
            fetchedVideos = searchResponse.data || [];
  
            // Apply Additional Filters
            if (accessFilter === 'public') {
              fetchedVideos = fetchedVideos.filter(video => video.isPublic);
            } else if (accessFilter === 'private') {
              fetchedVideos = fetchedVideos.filter(video => !video.isPublic);
            }
  
            if (genderFilter) {
              const genderResponse = await axios.get(`${API_URL}/video/gender`, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: { gender: genderFilter }
              });
              const genderVideos = genderResponse.data || [];
              fetchedVideos = fetchedVideos.filter(video =>
                genderVideos.some(gv => gv.videoId === video.videoId)
              );
            }
  
            if (annotatedFilter) {
              fetchedVideos = fetchedVideos.filter(video => annotatedVideos[video.videoId]);
            }
  
            // Retrieve Status and Thumbnail for Each Video
            const videoDataPromises = fetchedVideos.map(async (video) => {
              const statusResponse = await axios.get(
                `${API_URL}/video/${video.videoId}/status`,
                { headers: { 'Authorization': `Bearer ${token}` } }
              );
              return {
                ...video,
                status: statusResponse.data.status,
                thumbnailUrl: `${CLOUDFRONT_URL}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
              };
            });
            fetchedVideos = await Promise.all(videoDataPromises);
          } else {
            // Handle other videoTypeFilters ('uploads', 'starred', 'annotations', 'all')
            if (videoTypeFilter === 'uploads') {
              const uploadsResponse = await axios.get(`${API_URL}/user/uploads`, {
                headers: { 'Authorization': `Bearer ${token}` }
              });
              fetchedVideos = uploadsResponse.data?.uploadedVideos || [];
  
              const videoDataPromises = fetchedVideos.map(async (video) => {
                const statusResponse = await axios.get(
                  `${API_URL}/video/${video.videoId}/status`,
                  { headers: { 'Authorization': `Bearer ${token}` } }
                );
                return {
                  ...video,
                  status: statusResponse.data.status,
                  thumbnailUrl: `${CLOUDFRONT_URL}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
                };
              });
              fetchedVideos = await Promise.all(videoDataPromises);
            } else if (videoTypeFilter === 'starred') {
              const starredResponse = await axios.get(`${API_URL}/user/starred`, {
                headers: { 'Authorization': `Bearer ${token}` }
              });
              fetchedVideos = starredResponse.data?.starredVideos || [];
  
              const videoDataPromises = fetchedVideos.map(async (video) => {
                const statusResponse = await axios.get(
                  `${API_URL}/video/${video.videoId}/status`,
                  { headers: { 'Authorization': `Bearer ${token}` } }
                );
                return {
                  ...video,
                  status: statusResponse.data.status,
                  thumbnailUrl: `${CLOUDFRONT_URL}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
                };
              });
              fetchedVideos = await Promise.all(videoDataPromises);
            } else if (videoTypeFilter === 'annotations') {
              const annotationsResponse = await axios.get(`${API_URL}/meta_annotation/annotatedVideos`, {
                headers: { 'Authorization': `Bearer ${token}` }
              });
              const annotatedVideoIds = annotationsResponse.data?.map(video => video.videoId) || [];
  
              const accessibleResponse = await axios.get(`${API_URL}/video/accessible`, {
                headers: { 'Authorization': `Bearer ${token}` }
              });
              fetchedVideos = accessibleResponse.data || [];
  
              fetchedVideos = fetchedVideos.filter(video => annotatedVideoIds.includes(video.videoId));
  
              const videoDataPromises = fetchedVideos.map(async (video) => {
                const statusResponse = await axios.get(
                  `${API_URL}/video/${video.videoId}/status`,
                  { headers: { 'Authorization': `Bearer ${token}` } }
                );
                return {
                  ...video,
                  status: statusResponse.data.status,
                  thumbnailUrl: `${CLOUDFRONT_URL}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
                };
              });
              fetchedVideos = await Promise.all(videoDataPromises);
            } else {
              // Default: videoTypeFilter === 'all'
              fetchedVideos = await fetchAccessibleVideos(token);
            }
          }
        }
  
        // Update videoFiles state
        setVideoFiles(fetchedVideos);
  
        // Fetch Surgeon Details Based on Fetched Videos
        try {
          const infoPromises = fetchedVideos
            .filter(video => video.uploadedBy)
            .map(video =>
              axios.all([
                axios
                  .get(`${API_URL}/user/name/${video.uploadedBy}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                  })
                  .then(response => ({ videoId: video.videoId, surgName: response.data.name }))
                  .catch(() => ({ videoId: video.videoId, surgName: 'Unknown' })),
                axios
                  .get(`${API_URL}/user/${video.uploadedBy}/organisation`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                  })
                  .then(response => ({ videoId: video.videoId, orgName: response.data.organisation }))
                  .catch(() => ({ videoId: video.videoId, orgName: 'Unknown' }))
              ])
            );
  
          const combinedResponses = await Promise.all(infoPromises);
          const surgeonDetails = combinedResponses.map(([nameRes, orgRes]) => ({
            videoId: nameRes.videoId,
            surgName: nameRes.surgName,
            orgName: orgRes.orgName
          }));
  
          setSurgeonInfo(surgeonDetails);
        } catch (error) {
          console.error('Error fetching surgeon details:', error);
        }
  
        // Implement Minimum Loading Duration (e.g., 500ms)
        const elapsedTime = Date.now() - startTime;
        const minimumLoadingTime = 500; // in milliseconds
        if (elapsedTime < minimumLoadingTime) {
          await new Promise(resolve => setTimeout(resolve, minimumLoadingTime - elapsedTime));
        }
  
        setPage(1); // Reset to page 1 each time we fetch new data
      } catch (error) {
        console.error('Error fetching videos and metadata:', error);
        setError('Error fetching videos and metadata');
      } finally {
        setLoading(false);
      }
    };
  
    fetchDataAndMetadata();
  }, [
    annotatedFilter,
    accessFilter,
    genderFilter,
    videoTypeFilter,
    searchTerm,
    currentGroupId,
    authHeader,
    annotatedVideos
    // Removed videoFiles from dependencies to prevent infinite loop
  ]);











  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        const token = authHeader.split(' ')[1];
        const response = await axios.get(`${API_URL}/group/my-groups`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        setUserGroups(response.data.groups || []);
      } catch (err) {
        console.error('Error fetching user groups:', err);
      }
    };
    fetchUserGroups();
  }, [authHeader]);




  const handleDelete = async (videoId) => {
    try {
      const token = authHeader.split(' ')[1];
      await axios.delete(`${API_URL}/video/${videoId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      await axios.delete(`${API_URL}/storage/delete/${videoId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setVideoFiles(prev => prev.filter(video => video.videoId !== videoId));
    } catch (error) {
      console.error('Error deleting video:', error);
    }
  };

  const handleUploadClick = () => {
    navigate('/upload_video');
  };

  const handleSearchClick = () => {
    setSearchTerm(searchInput.trim());
  };

  const handleResetFilters = () => {
    setAnnotatedFilter(false);
    setAccessFilter('all');
    setGenderFilter('');
    setVideoTypeFilter('all');
    setSearchInput('');
    setSearchTerm('');
    // Clear group selection if any
    setCurrentGroupId(null);
    setPage(1);
  };

  // ---------------- Group Click Handler ----------------
  // Instead of directly updating videoFiles, update the `currentGroupId` state to trigger the useEffect.
  const handleGroupClick = (group) => {
    setCurrentGroupId(group.groupId);
    // When a group is selected, you might also want to clear any other filters:
    setVideoTypeFilter('all');
    setAccessFilter('all');
    setGenderFilter('');
    setAnnotatedFilter(false);
    setPage(1);
  };

  // The “All Videos” click sets the currentGroupId to null so that the effect loads accessible or filtered videos.
  const handleAllVideosClick = () => {
    setCurrentGroupId(null);
    setVideoTypeFilter('all');
    setAccessFilter('all');
    setGenderFilter('');
    setAnnotatedFilter(false);
    setPage(1);
  };

  // ---------------- Pagination Logic ----------------
  const startIndex = (page - 1) * videosPerPage;
  const endIndex = page * videosPerPage;
  const currentPageVideos = videoFiles
    .filter(video => video.status === 'complete')
    .slice(startIndex, endIndex);

  const totalPages = Math.ceil(
    videoFiles.filter(video => video.status === 'complete').length / videosPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const toggleFilterSection = (section) => {
    switch (section) {
      case 'videoType':
        setShowVideoTypeFilter(prev => !prev);
        break;
      case 'access':
        setShowAccessFilter(prev => !prev);
        break;
      case 'gender':
        setShowGenderFilter(prev => !prev);
        break;
      default:
        break;
    }
  };

  const toggleSection = (section) => {
    if (section === 'search') {
      setShowSearch(!showSearch);
    } else if (section === 'filters') {
      setShowFilters(!showFilters);
    }
  };

  // Helper function to format date as relative time
  function formatUploadDate(dateString) {
    const uploadDate = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - uploadDate) / 1000);

    if (diffInSeconds < 60) {
      return "Just now";
    }
    const minutes = Math.floor(diffInSeconds / 60);
    if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    }
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    }
    const days = Math.floor(hours / 24);
    if (days < 7) {
      return `${days} day${days === 1 ? "" : "s"} ago`;
    }
    if (days < 30) {
      const weeks = Math.floor(days / 7);
      return `${weeks} week${weeks === 1 ? "" : "s"} ago`;
    }
    if (days < 365) {
      const months = Math.floor(days / 30);
      return `${months} month${months === 1 ? "" : "s"} ago`;
    }
    const years = Math.floor(days / 365);
    return `${years} year${years === 1 ? "" : "s"} ago`;
  }

  const handleGroupMouseEnter = (group, event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setHoveredGroupPos({
      x: rect.right, // Adjust this value if needed
      y: rect.top + rect.height / 2 -10, // Adjust if needed
    });
    setHoveredGroupName(group.groupName);
  };

  const handleGroupMouseLeave = () => {
    setHoveredGroupName(null);
  };

  
  return (
    <div className="ch-cases-home-container">
      {/* Sidebar */}
      <div className="group-sidebar">
        {/* Pinned filter groups */}
        <div className="pinned-groups">
          <div
            className="group-container"
            onClick={handleAllVideosClick}
            onMouseEnter={(e) =>
              handleGroupMouseEnter({ groupName: 'All Videos' }, e)
            }
            onMouseLeave={handleGroupMouseLeave}
          >
            <div className="group-circle all-circle">A</div>
            <span className="group-name">All Videos</span>
          </div>
          <div
            className="group-container"
            onClick={() => {
              setCurrentGroupId(null);
              setVideoTypeFilter('uploads');
              setAccessFilter('all');
              setGenderFilter('');
              setAnnotatedFilter(false);
              setPage(1);
            }}
            onMouseEnter={(e) =>
              handleGroupMouseEnter({ groupName: 'Uploaded' }, e)
            }
            onMouseLeave={handleGroupMouseLeave}
          >
            <div className="group-circle uploads-circle">U</div>
            <span className="group-name">Uploaded</span>
          </div>
          <div
            className="group-container"
            onClick={() => {
              setCurrentGroupId(null);
              setVideoTypeFilter('annotations');
              setAccessFilter('all');
              setGenderFilter('');
              setAnnotatedFilter(true);
              setPage(1);
            }}
            onMouseEnter={(e) =>
              handleGroupMouseEnter({ groupName: 'Annotated' }, e)
            }
            onMouseLeave={handleGroupMouseLeave}
          >
            <div className="group-circle annotated-circle">A</div>
            <span className="group-name">Annotated</span>
          </div>
          <div
            className="group-container"
            onClick={() => {
              setCurrentGroupId(null);
              setVideoTypeFilter('starred');
              setAccessFilter('all');
              setGenderFilter('');
              setAnnotatedFilter(false);
              setPage(1);
            }}
            onMouseEnter={(e) =>
              handleGroupMouseEnter({ groupName: 'Starred' }, e)
            }
            onMouseLeave={handleGroupMouseLeave}
          >
            <div className="group-circle starred-circle">S</div>
            <span className="group-name">Starred</span>
          </div>
        </div>
        {/* Divider */}
        <div className="group-sidebar-divider"></div>
        {/* Realgroups area, made scrollable via CSS */}
        <div className="realgroups-container">
          {userGroups.length > 0 ? (
            userGroups.map((group) => (
              <div
                key={group.groupId}
                className="group-container"
                onClick={() => handleGroupClick(group)}
                onMouseEnter={(e) => handleGroupMouseEnter(group, e)}
                onMouseLeave={handleGroupMouseLeave}
              >
                <div className="group-circle custom-group-circle">
                  {group.groupName.charAt(0).toUpperCase()}
                </div>
                <span className="group-name">{group.groupName}</span>
              </div>
            ))
          ) : (
            Array(4)
              .fill(null)
              .map((_, index) => (
                <div key={index} className="group-container preloader-group-container">
                  <div className="group-circle preloader-circle"></div>
                  <div className="preloader-rectangle group-name-placeholder"></div>
                </div>
              ))
          )}
        </div>
      </div>

      {/* Main content */}
      <div className="ch-cases-home-content">
      {hoveredGroupName && (
          <Tooltip
            x={hoveredGroupPos.x}
            y={hoveredGroupPos.y}
            text={hoveredGroupName}
          />
        )}
        <div className="top-search-pagination-bar">
          <button className="upload-icon-button" onClick={handleUploadClick}>
            <UploadVideoIcon className="upload-video-icon" />
          </button>
          <div className="search-wrapper">
            <input
              type="text"
              className="search-input-field"
              placeholder="Search videos..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearchClick();
                }
              }}
            />
            <button
              className="search-icon-button"
              onClick={handleSearchClick}
            >
              🔍
            </button>
          </div>
          <div className="pagination-bar">
            <button
              className={`arrow-button ${page === 1 ? 'disabled' : ''}`}
              onClick={() => page > 1 && setPage(page - 1)}
            >
              &lt;
            </button>
            {getPaginationRange(page, totalPages).map((label, idx) => {
              if (label === '...') {
                return (
                  <span key={`dots-${idx}`} className="pagination-dots">
                    ...
                  </span>
                );
              }
              return (
                <button
                  key={label}
                  className={`page-button ${page === label ? 'active' : ''}`}
                  onClick={() => typeof label === 'number' && setPage(label)}
                >
                  {label}
                </button>
              );
            })}
            <button
              className={`arrow-button ${page === totalPages || totalPages === 0 ? 'disabled' : ''}`}
              onClick={() => page < totalPages && setPage(page + 1)}
            >
              &gt;
            </button>
          </div>
        </div>
        <div className="ch-cases-home-video-grid">
        {loading ? (
          <div className="ch-loader-wrapper">
            <div className="ch-loader"></div>
          </div>
        ) : (
            <>
              {currentPageVideos.length === 0 && !loading && (
                <div className="no-videos-container">
                  <div className="no-videos-icon">📹</div>
                  <div className="no-videos-text">No videos found</div>
                  <div className="no-videos-suggestion">
                    Try uploading a video or adjusting your filters.
                  </div>
                </div>
              )}
{currentPageVideos.map((video) => (
  <div key={video.videoId} className="ch-editor-container">
    <Link
      to={`/cases_video/${video.videoId}`}
      className="ch-editor-container-link"
    >
      <div className="ch-video-thumbnail">
        <img
          src={video.thumbnailUrl}
          alt={`${video.videoTitle} thumbnail`}
          className="thumbnail-image"
          onError={(e) => {
            e.target.style.display = 'none';
            e.target.onerror = null;
          }}
        />
        <div className="info-bubble">
          <div className="info-title">{video.surgeryType || 'Unknown Operation'}</div>
          <div className="info-procedure">{video.surgerySpeciality || 'Unknown Procedure'}</div>
          <div className="info-description">
            {video.videoDescription || 'No description available.'}
          </div>
        </div>
      </div>
    </Link>
    <div className="ch-text-container">
      <div className="video-title">{video.videoTitle}</div>
      <div className="video-footer">
        <span className="surgeon-name">
          {surgeonInfo.find(info => info.videoId === video.videoId)?.surgName || 'Unknown Surgeon'}
        </span>
        <span className="video-footer-spacer"></span>
        <span className="upload-date">
          {video.uploadDate ? formatUploadDate(video.uploadDate) : 'Unknown Date'}
        </span>
        <button
          className="delete-button-footer"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering Link navigation
            e.preventDefault(); // Prevent unintended default behaviors
            if (window.confirm('Are you sure you want to delete this video?')) {
              handleDelete(video.videoId);
            }
          }}
        >
          Delete
        </button>
      </div>
    </div>
  </div>
))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CasesHome;